import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!';

  return (
    <>
      <p>Loading</p>
    </>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `everyone-agency-ltd`,
  linkResolver:
    ({ field, value, node }) =>
    (doc) => {
      if (doc.type === 'homepage') {
        return `/`;
      } else if (doc.type === 'news_article') {
        return `/news/${doc.uid}`;
      } else if (doc.type === 'artist') {
        return `/artists/${doc.uid}`;
      } else if (doc.type === 'client_page') {
        return `/treatment/${doc.uid}`;
      } else if (doc.type === 'animation') {
        return `/animation/${doc.uid}`;
        // if (doc.data.artist !== undefined) {
        //   if (doc.data.artist.document !== undefined) {
        //     if (doc.data.artist.document.uid !== undefined) {
        //       return `/artists/${doc.data.artist.document.uid}/animation/${doc.uid}/`;
        //     } else {
        //       return `/animation/${doc.uid}`;
        //     }
        //   } else {
        //     return `/animation/${doc.uid}`;
        //   }
        // } else {
        //   return `/animation/${doc.uid}`;
        // }
      } else {
        return `/${doc.uid}`;
      }
    },
});

// import React, { useEffect } from 'react';
// import { graphql, navigate, useStaticQuery } from 'gatsby';
// import { usePrismicPreview } from 'gatsby-source-prismic';

// export const PreviewPage = ({ location }) => {
//   const allPrismicPages = useStaticQuery(graphql`
//     {
//       allPrismicArtist {
//         edges {
//           node {
//             uid
//           }
//         }
//       }
//       allPrismicClientPage {
//         edges {
//           node {
//             uid
//           }
//         }
//       }
//       allPrismicNewsArticle {
//         edges {
//           node {
//             uid
//           }
//         }
//       }
//       allPrismicHomepage {
//         edges {
//           node {
//             uid
//           }
//         }
//       }
//       allPrismicAbout {
//         edges {
//           node {
//             uid
//           }
//         }
//       }
//     }
//   `);

//   const artistUIDS = allPrismicPages.allPrismicArtist.edges.map(
//     (node) => node.node.uid
//   );

//   const clientPage = allPrismicPages.allPrismicClientPage.edges.map(
//     (node) => node.node.uid
//   );

//   const newsUIDS = allPrismicPages.allPrismicNewsArticle.edges.map(
//     (node) => node.node.uid
//   );

//   const homeUIDS = allPrismicPages.allPrismicHomepage.edges.map(
//     (node) => node.node.uid
//   );

//   const aboutUIDS = allPrismicPages.allPrismicAbout.edges.map(
//     (node) => node.node.uid
//   );

//   const pathResolver = () => (doc) => {
//     const previewedUID = doc.uid;

//     if (newsUIDS.includes(previewedUID)) {
//       return `/news/${previewedUID}`;
//     } else if (clientPage.includes(previewedUID)) {
//       return `/treatment/${previewedUID}`;
//     } else if (artistUIDS.includes(previewedUID)) {
//       return `/artists/${previewedUID}`;
//     } else if (homeUIDS.includes(previewedUID)) {
//       return '/';
//     } else if (aboutUIDS.includes(previewedUID)) {
//       return `/${previewedUID}`;
//     } else {
//       return '/unpublishedPreview';
//     }
//   };

//   const { previewData, path } = usePrismicPreview(location, {
//     repositoryName: 'everyone-agency-ltd',
//     pathResolver,
//   });

//   useEffect(() => {
//     if (previewData && path) {
//       window.__PRISMIC_PREVIEW_DATA__ = previewData;
//       navigate(path);
//     }
//   }, [path, previewData]);

//   return <div>Loading preview...</div>;
// };

// export default PreviewPage;

// // const PreviewPage = ({ location }) => {
// //   const { previewData, path } = usePrismicPreview(location, {
// //     repositoryName: 'everyone-agency-ltd',
// //     linkResolver: ({ field, value, node }) => doc => {
// //       if (doc.type === 'homepage') {
// //         return `/`;
// //       } else if (doc.type === 'news_article') {
// //         return `/news/${doc.uid}`;
// //       } else if (doc.type === 'artist') {
// //         return `/artists/${doc.uid}`;
// //       } else if (doc.type === 'client_page') {
// //         return `/treatment/${doc.uid}`;
// //       } else if (doc.type === 'about') {
// //         return `/about`;
// //       } else {
// //         return `/`;
// //       }
// //     },
// //   });

// //   useEffect(() => {
// //     if (previewData && path) {
// //       window.__PRISMIC_PREVIEW_DATA__ = previewData;
// //       navigate(path);
// //     }
// //   }, [previewData, path]);

// //   return <div>Loading preview...</div>;
// // };

// // export default PreviewPage;
